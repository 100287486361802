import React, { useState, useContext, createContext, useEffect } from "react";
// import { decrypt, encrypt } from "../util/encrypt";
import { initializeApp } from "firebase/app";

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);
// This context provider is passed to any component requiring the context
const AuthProvider = ({ children }) => {
  const [localAuth, setlocalAuth] = useState(false);
  const [localToken, setlocalToken] = useState(false);
  const [localRememberMe, setlocalRememberMe] = useState(null);
  const [localUsername, setlocalUsername] = useState('');
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID,
  };
  const firebase = initializeApp(firebaseConfig);
  const authUser =
    localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")) : null
  const token = localToken ? localToken : localStorage.getItem("token") ? localStorage.getItem("token") : null;
  const rememberMe = localRememberMe !== null ? localRememberMe : localStorage.getItem("rememberMe") ? localStorage.getItem("rememberMe") : null;
  const authName = localUsername ? localUsername : localStorage.getItem("username") ? localStorage.getItem("username") : null;



  const setPermission = (key, rolePermissions) => {
    if (rolePermissions) {
      localStorage.setItem("user-permissions", JSON.stringify(rolePermissions));
    }

  }

  const setAuthName = (e) => {
    if (rememberMe) {
      setlocalUsername(e)
      localStorage.setItem("username", e);
    }
  }

  const permissions = null;
  const getPermissions = () => {
    if (!permissions) {
      if (!localStorage.getItem("user-permissions")) {
        return
      }
      let content = localStorage.getItem("user-permissions")
      const temp = JSON.parse(content);
      return temp
    } else {
      return permissions
    }
  }
  const checkPermission = (p) => {
    const ps = getPermissions()
    if (ps) {
      if (ps["SUPER"]) {
        return (p.includes("ACCOUNT") || p.includes("REPORT") || p.includes("ROLE")
          || p.includes("COMPANY") || p.includes("BRANCH"))

      }
      return ps[p]
    }
    return false
  }

  const setAuthUser = (acc) => {
    setPermission(acc.id, acc.role.permissions)
    localStorage.setItem("authUser", JSON.stringify(acc));
  }

  const setToken = (t) => {
    localStorage.setItem("token", t);
    setlocalToken(t)
  }

  const setRefreshToken = (t) => {
    localStorage.setItem("refresh_token", t);
  }

  const removeToken = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("refresh_token")
  }
  const removeAuthUser = () => {
    localStorage.removeItem("authUser")
  }
  const setRememberMe = (e) => {
    localStorage.setItem("rememberMe", e)
    setlocalRememberMe(e)
  }

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
        setToken,
        setRefreshToken,
        token,
        removeAuthUser,
        removeToken,
        checkPermission,
        setRememberMe,
        getPermissions,
        rememberMe,
        authName,
        setAuthName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
export default AuthProvider;