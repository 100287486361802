import i18next from "../i18n";
import { getBoxappBranchBackend } from "../helpers/boxapp_branch_helper";
import { getBoxappAccountBackend } from "../helpers/boxapp_account_helper";
import { getBoxappRoleBackend } from "../helpers/boxapp_role_helper";


export const mfaOptionGroup = [{
  options: [
    { label: i18next.t('GOOGLE_AUTHENTICATOR'), value: "GOOGLE_AUTHENTICATOR" },
    { label: i18next.t('EMAIL'), value: "EMAIL" },
    { label: i18next.t('SMS'), value: "SMS" }
  ]
}]
export const optionOrderType = {
  label: i18next.t('Order Type'), options: [
    { label: i18next.t("ALL"), value: null },
    { label: i18next.t("RENT"), value: "Rent" },
    { label: i18next.t("EXTEND"), value: "Extend" },
    { label: i18next.t("TRANSFER"), value: "Transfer" },
    { label: i18next.t("PENALTY"), value: "Penalty" },
    { label: i18next.t("DEPOSIT"), value: "Deposit" },
  ]
}
export const optionGender = [
  {
    options: [
      { label: i18next.t('M'), value: "M" },
      { label: i18next.t('F'), value: "F" },
      { label: i18next.t('N/A'), value: "N/A" }
    ]
  },
];

export const optionStorageStatus = [
  {
    label: "Status",
    options: [
      { label: i18next.t('AVAILABLE'), value: "AVAILABLE" },
      { label: i18next.t('PENDING'), value: "PENDING" },
      { label: i18next.t('RENT'), value: "RENT" },
      { label: i18next.t('DISABLE'), value: "DISABLE" },
    ],
  },
];




export const optionOrderStatus = [
  {
    label: "Status",
    options: [
      { label: i18next.t('ALL'), value: "ALL" },
      { label: i18next.t('PENDING'), value: "PENDING" },
      { label: i18next.t('CLOSED'), value: "CLOSED" },
      { label: i18next.t('VOID'), value: "VOID" },
      { label: i18next.t('CANCELED'), value: "CANCELED" },
      { label: i18next.t('COMPLETED'), value: "COMPLETED" },
      { label: i18next.t('TERMINATED'), value: "TERMINATED" },
      // { label: i18next.t('EXTEND'), value: "EXTEND" },
      { label: i18next.t('REFUND'), value: "REFUND" },
    ],
  },
];
export const optionBranch = () => {
  return getBoxappBranchBackend().getBranches().then((data) => {

    return ([{ label: i18next.t("ALL"), value: null }, ...data?.branches?.data?.filter((b)=>!b?.disable)?.sort((a, b) => a.sort - b.sort)?.map((branch) => {
      return { label: branch.name[i18next.language], value: branch.id }
    }) ?? []])
  }).catch((err) => {
    console.log(err)
    return ([{ label: i18next.t("ALL"), value: null }])
  })


}

export const optionUser = () => {
  return getBoxappAccountBackend().getAccounts().then((data) => {

    return data?.accounts?.data?.map((account) => {
      return { label: account.display_name, value: account.id }
    }) ?? []
  }).catch((err) => {
    console.log(err)
    return ([])
  })


}


export const optionRole = () => {
  return getBoxappRoleBackend().getRoles().then((data) => {

    return data?.roles?.data?.map((role) => {
      return { label: role.name, value: role.id }
    }) ?? []
  }).catch((err) => {
    console.log(err)
    return ([])
  })


}


export const optionLeadFromGroup = [
  {
    options: [
      { label: i18next.t('Phone'), value: "Phone" },
      { label: i18next.t('Facebook'), value: "Facebook" },
      { label: i18next.t('Instagram'), value: "Instagram" },
      { label: i18next.t('Google Ad'), value: "Google Ad" },
      { label: i18next.t('Whatsapp'), value: "Wechat" },
      { label: i18next.t('Website'), value: "Website" },
      { label: i18next.t('Walkin'), value: "Walkin" },
      { label: i18next.t('Friend Refer'), value: "Friend Refer" },
      { label: i18next.t('Current Customer'), value: "Current Customer" },
      { label: i18next.t('Event'), value: "Event" },
      { label: i18next.t('Others'), value: "Others" },
    ]
  },
];

export const optionLeadCallStatus = [
  {
    options: [
      { label: i18next.t('PENDING'), value: "PENDING" },
      { label: i18next.t('FOLLOWING'), value: "FOLLOWING" },
      { label: i18next.t('CLOSING'), value: "CLOSING" },
      { label: i18next.t('FAILED'), value: "FAILED" },
    ]
  },
];
export const optionPriority = [
  {
    options: [
      { label: i18next.t('Low'), value: 1 },
      { label: i18next.t('Medium'), value: 2 },
      { label: i18next.t('High'), value: 3 },
      { label: i18next.t('Very High'), value: 4 }
    ]
  },
];

export const optionYN = [
  {
    options: [
      { label: i18next.t('Y'), value: "Y" },
      { label: i18next.t('N'), value: "N" },
    ]
  },
];

export const optionLeadStatus = [
  {
    options: [
      { label: i18next.t('NEW LEAD'), value: "NEW" },
      { label: i18next.t('i18next'), value: "i18next" },
      { label: i18next.t('INTERESTED'), value: "INTERESTED" },
      { label: i18next.t('DESIRED'), value: "DESIRED" },
      { label: i18next.t('CLOSING'), value: "CLOSING" },
    ]
  },
];

export const optionContractStatus = [
  {
    options: [
      { label: i18next.t('ACTIVE'), value: "ACTIVE" },
      { label: i18next.t('CONTRACT END'), value: "END" },
      { label: i18next.t('CANCEL'), value: "CANCEL" },
      { label: i18next.t('TERMINATE'), value: "TERMINATE" },
    ]
  }
];


export const titleOptionGroup = [
  {
    options: [
      { label: i18next.t('N/A'), value: "N/A" },
      { label: i18next.t('Mr.'), value: "Mr" },
      { label: i18next.t('Mrs.'), value: "Mrs" },
      { label: i18next.t('Miss'), value: "Miss" },

    ]
  }
];
export const optionStatus = [
  {
    options: [
      { label: i18next.t('Active'), value: "Active" },
      { label: i18next.t('Disable'), value: "Disable" }
    ]
  },
];

export const optionCustomerTypeGroup = [
  {
    options: [
      {
        label:
          i18next.t('Individual'), value: "CUSTOMER_INDIVIDUAL"
      },
      { label: i18next.t('Corporate'), value: "CUSTOMER_CORPORATE" }
    ]
  }

]


export const optionCustomerStatus = [
  {
    options: [
      {
        label:
          i18next.t('Active'), value: "Active"
      },
      { label: i18next.t('Disable'), value: "Disable" }
    ]
  }

]

export const optionCategory = (branches) => {
  return [{
    options: [...[{ label: i18next.t("GLOBAL"), value: undefined },], ...branches?.map((branch) => {
      return { label: branch?.name[i18next.language], value: branch?.id }
    }) ?? []]
  }]
}

export const optionFeature = (branches) => {
  return [{
    options: [...[{ label: i18next.t("GLOBAL"), value: undefined },], ...branches?.map((branch) => {
      return { label: branch?.name[i18next.language], value: branch?.id }
    }) ?? []]
  }]
}


export const paidItemchargeTypeOption = (branch) => {
  return [{
    options: branch?.charge_types?.sort((a, b) => a.sort - b.sort).map((charge_type) =>
    ({
      label: charge_type?.charge_name[i18next.language],
      value: charge_type?.id,
      type: charge_type?.charge_type,
      unit_price: charge_type?.unit_price,
      default_quantity: charge_type?.default_quantity,
      quantize: charge_type?.quantize,
      charge_name: charge_type?.charge_name,
      charge_mode: charge_type?.charge_mode,
      unit: charge_type?.unit,
    }))
  }]
}

export const branchChargeTypeOptions = [
  {
    options: [
      { label: i18next.t('WATER BILL'), value: "WATER_BILL" },
      { label: i18next.t('ELECTRIC BILL'), value: "ELECTRIC_BILL" },
      { label: i18next.t('CLEANING FEE'), value: "CLEANING_FEE" },
      { label: i18next.t('GAS BILL'), value: "GAS_BILL" },
      { label: i18next.t('SERVICE CHARGE'), value: "SERVICE_CHARGE" },
      { label: i18next.t('Management Fee'), value: "MANAGEMENT_FEE" },
      { label: i18next.t('Rating'), value: "RATING" },
      { label: i18next.t('Government Rent'), value: "GOVERNMENT_RENT" },
      { label: i18next.t('Expired Interest'), value: "EXPIRED_INTEREST" },
      { label: i18next.t('Expired Fee'), value: "EXPIRED_FEE" },
      { label: i18next.t('Penalty'), value: "PENALTY" },
      { label: i18next.t('INCIDENTALS'), value: "INCIDENTALS" },
      { label: i18next.t('MAINTENANCE FEE'), value: "MAINTENANCE_FEE" },
      { label: i18next.t('REPAIRS FEE'), value: "REPAIRS_FEE" },
      { label: i18next.t('HOUSEKEEPING FEE'), value: "HOUSEKEEPING_FEE" },
      { label: i18next.t('INSURANCE FEE'), value: "INSURANCE_FEE" },
      { label: i18next.t('BORROWING FEE'), value: "BORROWING_FEE" },
      { label: i18next.t('BOOKING FEE'), value: "BOOKING_FEE" },
      { label: i18next.t('EQUIPMENT FEE'), value: "EQUIPMENT_FEE" },
      { label: i18next.t('OTHER'), value: "OTHER" },
    ]
  },
];


export const optionPaymentStatusGroup = [
  {
    options: [
      { label: i18next.t('PENDING'), value: "PENDING" },
      { label: i18next.t('CANCELED'), value: "CANCELED" },
      { label: i18next.t('COMPLETED'), value: "COMPLETED" },
    ],
  },
];
export const optionPayment = [
  {
    options: [
      { label: i18next.t('CASH'), value: "CASH" },
      { label: i18next.t('CHEQUE'), value: "CHEQUE" },
      { label: i18next.t('EPS'), value: "EPS" },
      { label: i18next.t('FPS'), value: "FPS" },
      { label: i18next.t('BANK TRANSFER'), value: "BANK TRANSFER" },
      { label: i18next.t('VISA'), value: "VISA" },
      { label: i18next.t('MASTER'), value: "MASTER" },
      { label: i18next.t('AE'), value: "AE" },
      { label: i18next.t('ALIPAY'), value: "ALIPAY" },
      { label: i18next.t('WECHAT PAY'), value: "WECHAT PAY" },
      { label: i18next.t('TAP & GO'), value: "TAP & GO" },
      { label: i18next.t('OCTOPUS'), value: "OCTOPUS" },
      { label: i18next.t('ONLINE PAYMENT'), value: "ONLINE PAYMENT" },
      { label: i18next.t('PAYPAL'), value: "PAYPAL" },
      { label: i18next.t('CANCEL'), value: "CANCEL" },
    ],
  },
];
export const optionSchedulerStatus = [
  {
    label: i18next.t('Status'),
    options: [
      {
        label: i18next.t('ACTIVE'), value: "ACTIVE"
      },
      {
        label: i18next.t('INACTIVE'), value: "INACTIVE"
      },
      {
        label: i18next.t('CANCEL'), value: "CANCEL"
      },
      {
        label: i18next.t('COMPLETE'), value: "COMPLETE"
      },
      {
        label: i18next.t('INVALID'), value: "INVALID"
      }
    ]
  }];
