import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import {
    Col,
    Row,
    Card,
} from "reactstrap";
import { TabContent, TabPane, Collapse, NavLink, NavItem, CardText, Nav, CardTitle, CardSubtitle, CardHeader, Container } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

const CollapseForm = (props) => {
    return (
        <AvForm action="#" onSubmit={props.onSubmit}>
            <Card>
                <CardHeader className={props.isFinished ? "p-3 bg-success" : "p-3 bg-danger"}
                    style={{ cursor: "pointer" }} onClick={() => props.onCancel()}>
                    <Row>
                        <Col xs="10">
                            <h6 className="m-0 font-11 text-white">
                                <span
                                >
                                    {`${props.step} -  ${props.title}`}
                                </span>
                            </h6>
                        </Col>
                        <Col>
                            <div className="text-sm-right">
                                {props.isFinished && <i className="mdi mdi-check-bold align-middle mr-2"></i>}
                            </div>
                        </Col>
                    </Row>

                </CardHeader>
                <Collapse isOpen={props.open}>
                    {props.children}
                </Collapse>
            </Card>
        </AvForm>

    )
}
export default memo(CollapseForm);