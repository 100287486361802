import React, { useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import {
  TabContent, TabPane, NavLink, NavItem, Nav,
  FormGroup,
  Row,
  Col,
  Input,
  Button,
  Label,
  Card,
  CardBody,
  InputGroup,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from 'moment'
import "./paymentInfo.scss"
import UseAPI, { SUCCESS, API_UPDATE_PAYMENT } from "../../../api";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { BxButton } from "../../../components/Button"
import { optionPayment, optionPaymentStatusGroup } from "../../../util/options"
import { getBoxappOrderBackend } from "../../../helpers/boxapp_order_helper";
import {
  useGlobalModalContext,
  MODAL_TYPES,
} from "../../../context/GlobalModalContext";
const PaymentInfo = (props) => {
  const [remarks, setRemarks] = useState(props.invoice?.remarks);
  const { showModal } = useGlobalModalContext();
  const [paymentMethodGroup, setPaymentMethodGroup] = useState(null);
  const [paymentStatusGroup, setPaymentStatusGroup] = useState(null);
  const [refNo, setRefNo] = useState('')
  const [amount, setAmount] = useState(0)
  // const [amountError, setAmountError] = useState(null)
  const [paymentStatusGroupError, setPaymentStatusGroupError] = useState(null)
  const [paymentMethodGroupError, setPaymentMethodGroupError] = useState(null)
  // const [refNoError, setRefNoError] = useState(null)
  const [paymentDateError, setPaymentDateError] = useState(null)
  const [submitButtonBlock, setSubmitButtonBlock] = useState(false)

  const [paymentDate, setPaymentDate] = useState('')
  const [{ status, response }, makeRequest] = UseAPI();

  useEffect(() => {
    // if (status === SUCCESS && props.order.invoices[0].payment) {
    if (status === SUCCESS) {
      if (props.mode === "confirm" || props.mode === "step") {
        if (response.storage) {
          setSubmitButtonBlock(false)
          props.onSuccess(response.storage)
        }
      } else if (response.payment) {
        if (!response.storage || props.mode === "edit") {
          setSubmitButtonBlock(false)
          if (response.payment.status === "COMPLETED") {
            props.onSuccess(response.payment)
          }
        }

      }
    }
  }, [status, response])

  useEffect(() => {
    if (!props.invoice)
      return
    const payment = props.invoice?.payment
    setAmount(Number(payment.amount))
    setRefNo(payment.ref_no)
    if (payment.payment_date) {
      setPaymentDate(new Date(payment.payment_date * 1000))
    }
    if (payment.status) {
      if (props.mode === 'confirm' || props.mode === 'step') {
        const op = optionPaymentStatusGroup[0].options.find((option) => {
          return option.value === 'COMPLETED'
        })
        setPaymentStatusGroup(op)
      } else {
        const op = optionPaymentStatusGroup[0].options.find((option) => {
          return option.value === payment.status
        })
        setPaymentStatusGroup(op)
      }
    }
    if (payment.payment_method) {
      const op = optionPayment[0].options.find((option) => {
        return option.value === payment.payment_method
      })
      setPaymentMethodGroup(op)
    }

  }, [props.invoice]);


  useEffect(() => {
    if (paymentMethodGroup) {
      if (paymentMethodGroup.value === "CASH") {
        setRefNo('')
      }
    }
  }, [paymentMethodGroup]);


  const onSubmit = async () => {

    if (!paymentStatusGroup) {
      setPaymentStatusGroupError(props.t('Payment Status') + ' ' + props.t('is required'))

      return
    } else {
      setPaymentStatusGroupError(null)
    }
    if (!paymentMethodGroup) {
      setPaymentMethodGroupError(props.t('Payment Method') + ' ' + props.t('is required'))

      return
    } else {
      setPaymentMethodGroupError(null)
    }

    if (!paymentDate) {
      setPaymentDateError(props.t('Paid At') + ' ' + props.t('is required'))

      return
    } else {
      setPaymentDateError(null)
    }
    if (props.mode === "step" && !props.lastStep) {
      props.newStep(
        {
          branch_id: props.order?.branch_id,
          paymentId: props.invoice?.payment.id,
          order_id: props.order.id,
          data: {
            ref_no: refNo,
            amount: amount,
            order_type: "Deposit",
            payment_method: paymentMethodGroup.value,
            payment_date: moment(paymentDate).unix(),
            status: paymentStatusGroup.value,

          }
        })

    } else {
      setSubmitButtonBlock(true)
      if (props.stepPayments) {
        console.log(props.stepPayments)
        const payInfo = Object.values(props.stepPayments)?.[0]
        console.log(payInfo)
        if (payInfo) {
          try {
            const { payment, _ } = await getBoxappOrderBackend().updatePayment(payInfo.branch_id, payInfo.order_id, payInfo.paymentId, payInfo.data)
            if (payment) {
              await makeRequest(API_UPDATE_PAYMENT, props.order?.branch_id, props.order?.id, props.invoice?.payment.id, {
                ref_no: refNo,
                amount: Number(amount),
                remarks: remarks,
                payment_method: paymentMethodGroup.value,
                payment_date: moment(paymentDate).unix(),
                status: paymentStatusGroup.value,
              })
            }
          } catch (err) {
            showModal(MODAL_TYPES.ALERT_MODAL, {
              title: props.t("Payment failed"),
              messageType: "error",
              message: err,
            });
          }
        }
      } else {
        if (props.invoice?.payment?.status === "COMPLETED") {
          //update remarks 
          await makeRequest(API_UPDATE_PAYMENT, props.order?.branch_id, props.order.id, props.invoice?.payment.id, {
            remarks: remarks,
          })
        } else {
          await makeRequest(API_UPDATE_PAYMENT, props.order?.branch_id, props.order.id, props.invoice?.payment.id, {
            ref_no: refNo,
            amount: Number(amount),
            remarks: remarks,
            payment_method: paymentMethodGroup.value,
            payment_date: moment(paymentDate).unix(),
            status: paymentStatusGroup.value,
          })
        }
      }


    }

  };
  return (
    <AvForm action="#" onSubmit={onSubmit}>
      <Card>
        <CardBody>
          <Row className="mb4">
            <Col sm="8">
              <Label>{props.t('Payment No')}:</Label>
              <span className="text-primary ml-2">{props.invoice?.payment?.payment_no ?? ''}</span>
              <span
                className={`font-size-13 ml-2 badge badge-pill ${props.order?.order_type === "Deposit" ? "badge-primary" : "badge-success"}`}
              >
                {props.t(props.order?.order_type)}
              </span>
              <span hidden={props.invoice?.invoice_type !== "Refund"}
                className={`font-size-13 ml-2 badge badge-pill badge-warning`}
              >
                {props.t(props.invoice?.invoice_type)}
              </span>
            </Col>
            <Col sm="4">
              <div className="text-sm-right mr-4">
                {props.t("created_at")} {moment.unix(props.invoice?.created_at).format("YYYY-MM-DD HH:mm:ss")}
              </div>
            </Col>
          </Row>

          <FormGroup>
            <Label>{props.t('Payment Status')}: </Label>
            <Select
              required
              value={paymentStatusGroup}
              isDisabled={props.mode === 'confirm' || props.mode === 'step' || props.invoice?.payment?.status === 'COMPLETED' || props.invoice?.payment?.status === 'CANCELED'}
              hidden={props.mode !== "edit"}
              onChange={(e) => setPaymentStatusGroup(e)}
              options={optionPaymentStatusGroup}
              classNamePrefix="select-selection"
            />
            <div className={'error'}>{paymentStatusGroupError ? paymentStatusGroupError : ''}</div>
          </FormGroup>
          <FormGroup>
            <Label>{props.t('Paid At')}:</Label>
            <InputGroup>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                className={props.invoice?.payment?.status === 'COMPLETED' || props.invoice?.payment?.status === 'CANCELED' ? "bg-light text-gray bg-opacity-10 form-control" : "form-control"}
                readOnly={props.invoice?.payment?.status === 'COMPLETED' || props.invoice?.payment?.status === 'CANCELED'}
                required
                selected={paymentDate}
                onChange={(date) => setPaymentDate(date)}
              />
            </InputGroup>
            <div className={'error'}>{paymentDateError ? paymentDateError : ''}</div>
          </FormGroup>
          <FormGroup>
            <Label> {props.t('Payment Method')}: </Label>
            <Select
              required
              isDisabled={props.invoice?.payment?.status === 'COMPLETED' || props.invoice?.payment?.status === 'CANCELED'}
              placeholder={props.t('Select...')}
              value={paymentMethodGroup}
              hidden={props.mode !== "edit"}
              onChange={(e) => setPaymentMethodGroup(e)}
              options={optionPayment}
              classNamePrefix="select-selection"
            />
            <div className={'error'}>{paymentMethodGroupError ? paymentMethodGroupError : ''}</div>
          </FormGroup>

          <FormGroup className="mb-4">
            <Label>{props.t('Price')}</Label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <Input
                className={"bg-light text-gray bg-opacity-10"}
                disabled={true}
                value={amount}
                name="amount"
                type="number"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </div>
          </FormGroup>

          {paymentMethodGroup?.value !== "CASH" && (
            <FormGroup className="mb-4"  >
              <Label>{props.t('Reference No')}:</Label>
              <Input
                value={refNo}
                name="refNo"
                type="text"
                onChange={(e) => {
                  setRefNo(e.target.value);
                }}
              />
            </FormGroup>
          )}
          {props.invoice?.paid_items && props.invoice?.paid_items.length > 0 && (
            <Row>
              <Col xs="12">
                <Label className="font-12" htmlFor="">{props.t('Paid Items')}</Label>
              </Col>
              <Col xs="12">
                {
                  props.invoice?.paid_items?.map((items, i) => {
                    return (
                      <Row className="ml-2 mb-1" key={i}>
                        {items?.item_type === "STORAGE" ? (
                          <Col xs="6" >
                            {`${props.t(items?.item_type)} - ${items?.item?.code}`}
                          </Col>
                        ) : (
                          <Col xs="6" >
                            {`${items?.item?.charge_name ? items?.item?.charge_name?.[props.i18n.language] : ""}`}
                          </Col>
                        )
                        }
                        <Col xs="6">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <Input
                              className={props.mode === "step" || props.invoice?.payment?.status === 'COMPLETED' || props.invoice?.payment?.status === 'CANCELED' ? "bg-light text-gray bg-opacity-10" : ""}
                              type="number"
                              maxLength="8"
                              name={"depositItemPrice-" + i}
                              disabled={true}
                              value={items?.final_price ?? 0}
                            />
                          </div>
                        </Col>
                      </Row>
                    )
                  })
                }
              </Col>
            </Row>

          )}

          {props.mode === "step" && props.lastStep !== props.firstStep && (
            <Row>
              <Col>
                {!props.firstStep && <BxButton color={"info"}
                  loading={submitButtonBlock}
                  disabled={submitButtonBlock || (props.depositOrder ? props.depositOrder?.status === "COMPLETED" : false)}
                  onClick={() => {
                    if (props.onClose) {
                      props.onClose()
                    }
                  }}>
                  {props.t('Previous Step')}
                </BxButton>}
                {!props.lastStep && <BxButton type="submit" color={"success"} >
                  {props.t('Next Step')}
                </BxButton>}
                {props.lastStep && <BxButton
                  loading={submitButtonBlock}
                  disabled={submitButtonBlock} type="submit" color={"success"} className={"ml-1"}  >
                  {props.t('Submit')}
                </BxButton>}
              </Col>
            </Row>
          )}
          <FormGroup className="mb-4">
            <Label>{props.t('Invoice')}{props.t('Remarks')}</Label>
            <Input
              type="textarea"
              maxLength="500"
              name="remarks"
              onChange={(e) => setRemarks(e.target.value)}
              value={remarks}
            />
          </FormGroup>
          {props.mode === "step" && props.lastStep && props.firstStep && <Row >
            <Col>
              <BxButton
                loading={submitButtonBlock}
                disabled={submitButtonBlock} type="submit" color={props.onClose ? "success" : "primary"} >
                {props.t('Submit')}
              </BxButton>
              {
                props.onClose && <Button type="button" color="secondary" onClick={props.onClose ? props.onClose : props.onClose}>
                  {props.t('Close')}
                </Button>
              }
            </Col>
          </Row>
          }
          {
            props.mode !== "step" && (
              <Row>
                <Col>
                  <BxButton
                    loading={submitButtonBlock}
                    disabled={submitButtonBlock}
                    type="submit" color={props.onClose ? "success" : "primary"}  >
                    {props.t('Submit')}
                  </BxButton>
                  {
                    props.onClose && <Button type="button" color="secondary" onClick={props.onClose ? props.onClose : props.onClose}>
                      {props.t('Close')}
                    </Button>
                  }
                </Col>
              </Row>
            )
          }
        </CardBody>
      </Card >
    </AvForm>
  );
};
export default withTranslation()(PaymentInfo);
