import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import "./storage.scss";
import { withTranslation } from 'react-i18next';
import PaymentInfo from "../../../pages/Orders/components/paymentInfo";
import StoragePaymentPanel from "./StoragePaymentPanel"

const StoragePaymentInfo = (props) => {

  const order = useMemo(() => props.order?.status === "COMPLETED" ? props.newOrder : props.order, [props.order])
  // const [depositOrder, setDepositOrder] = useState(null)

  // const totalStep = useMemo(() => props.depositOrder?.status === "COMPLETED" ? 1 : 2, [props.depositOrder])

  const [activeStep, setActiveStep] = useState(0)
  const [stepPayments, setStepPayments] = useState(null)


  const togglemodal = () => {
    props.onClose();
  };


  const titles = useMemo(() => !props.depositOrder ? [] : [props.t("Deposit Order"), props.t("Rental Order")], [props.depositOrder])

  return (
    <Modal
      isOpen={props.modal}
      role="dialog"
      backdrop="static"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={togglemodal}
    >
      <div className="modal-content">
        <ModalHeader toggle={togglemodal} tag="h4">
          <span>{props.t('Payment Info')}</span>
        </ModalHeader>
        <ModalBody>
          <div >
            <StoragePaymentPanel
              titles={titles}
              activeStep={activeStep}
              order={activeStep === 0 ? props.depositOrder ?? order : order}
              lastStep={activeStep === titles.length - 1 || activeStep === titles.length}
              firstStep={activeStep === 0}
              stepPayments={stepPayments}
              invoice={(titles.length === 1 ? order : activeStep === 0 ? props.depositOrder ?? order : order)?.invoices.find((inv) => inv)}
              onNext={(p) => {
                console.log(p)
                setStepPayments({ ...stepPayments, [activeStep]: p })
                setActiveStep((s) => s + 1)
              }}
              onSuccess={(p) => {
                console.log(p)
                props.onSuccess(props.mode, p)
              }}
              onClose={() => setActiveStep(0)}
            />
          </div>
        </ModalBody>
      </div >
    </Modal >)

};


export default withRouter(withTranslation()(StoragePaymentInfo));
