import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import "../../assets/css/datatables.scss";
import ColumnFilter from "./ColumnFilter";
import {
  Collapse,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

export const DataTable = (props) => {
  const [columnStatus, setColumnStatus] = useState([]);

  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    if (localStorage.getItem(props.id)) {
      const filter = JSON.parse(
        props.id ? localStorage.getItem(props.id) ?? "{}" : "{}"
      );
      setColumnStatus(
        props.data.columns?.filter(f=>f?.field !== "")?.map((col) => {
          try {
            const key = Object.keys(filter)?.find((f) => f === col.field);
            col.enable = filter[key];
          } catch (e) {
            col.enable = true;
          }
          return col;
        })
      );
    } else {
      const defaultFilter = props.data.columns?.filter(f=>f?.field !== "")?.map((col) => {
        col.enable = true;
        return col;
      });
      setColumnStatus(defaultFilter);
    }
  }, [props.data.columns]);

  useEffect(() => {
    if (columnStatus && columnStatus.length > 4) {
      const filter = {};
      columnStatus.forEach((col) => {
        filter[col.field] = !!col.enable;
      });
      if (props.id) {
        localStorage.setItem(props.id, JSON.stringify(filter));
      }
    }
  }, [columnStatus]);

  const toCollapse = () => {
    setCollapse(!collapse);
  };
  return (
    <React.Fragment>
      {props.columnfilter && (
        <Row className="">
          <Col sm="4">
            <Button
              outline
              className="btn waves-effect waves-light btn-sm "
              onClick={toCollapse}
              style={{ cursor: "pointer" }}
            >
              <i
                className={
                  collapse
                    ? "bx bxs-down-arrow "
                    : "bx bxs-up-arrow  font-size-16 align-middle mr-2"
                }
              ></i>
              {props.columnfilter?.label}
            </Button>
          </Col>
          <Col xs={8}></Col>
          <Collapse isOpen={!collapse} className="mb-4">
            <ColumnFilter
              title={props.columnfilter?.label}
              columns={columnStatus}
              onFilter={(k, v) => {
                setColumnStatus(
                  columnStatus.map((col) => {
                    if (col.field === k) {
                      col.enable = !col.enable;
                      let filter = {};
                      if (props.id) {
                        try {
                          filter = JSON.parse(
                            localStorage.getItem(props.id) ?? "{}"
                          );
                        } catch (e) {
                          filter = {};
                        }
                        filter[k] = col.enable;
                      }
                    }
                    return col;
                  })
                );
              }}
            />
            {props.render && props.render()}
          </Collapse>
        </Row>
      )}
      <div>
        <MDBDataTableV5
          className={props.className ? props.className : ""}
          {...props}
          render={undefined}
          data={{
            rows: props.data?.rows,
            columns: props.columnfilter
              ? columnStatus?.filter((col) => col.enable === true)
              : props.data?.columns,
          }}
        />
        {props.data.rows?.length ?? 0} out of Total: {props.total}
      </div>
    </React.Fragment>
  );
};
