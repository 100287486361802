import React, { useState, createContext, useContext } from "react";
import MessageModal from "../components/Modal/MessageModal";
import ActionModal from "../components/Modal/ActionModal";
import RemovalActionModal from "../components/Modal/RemovalActionModal";

export const MODAL_TYPES = {
  ALERT_MODAL: "ALERT_MODAL",
  ACTION_MODAL: "ACTION_MODAL",
  REMOVAL_ACTION_MODAL: "REMOVAL_ACTION_MODAL",
};

const MODAL_COMPONENTS = {
  [MODAL_TYPES.ALERT_MODAL]: MessageModal,
  [MODAL_TYPES.ACTION_MODAL]: ActionModal,
  [MODAL_TYPES.REMOVAL_ACTION_MODAL]: RemovalActionModal,
};

const initalState = {
  showModal: () => { },
  hideModal: () => { },
  isMobile: () => { },
  store: {},
};
const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

const GlobalModalProvider = ({ children }) => {
  const [store, setStore] = useState();
  const [loading, setLoading] = useState();
  const [apiCalls, setApiCalls] = useState([]);
  const { modalType, modalProps } = store || {};

  const showModal = (modalType, modalProps = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
  };

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  }
  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal, isMobile, loading, setLoading, apiCalls, setApiCalls }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
export default GlobalModalProvider;
